import {A2UNativeBrowser} from "a2u-capacitor-native-browser-plugin";
import {MarketingPixelsPlugin} from "./helpers/marketing-pixels/MarketingPixelsPlugin";
import {AbIAPClientStripe} from "./helpers/iap/AbIAPClientStripe";
import {StripeWebPlugin} from "./helpers/iap/StripeWebPlugin";
import {IAPManager} from "./helpers/iap/IAPManager";
import {FirebaseAnalytics} from "./helpers/Analytics/FirebaseAnalytics";
import {AuthPromoCodePlugin} from "./helpers/Auth/AuthPromoCodePlugin"
import {Users} from './db/Users.js';

export const moduleConfig = {
  plugins: {A2UNativeBrowser, MarketingPixelsPlugin, AbIAPClient: AbIAPClientStripe, StripeWebPlugin, IAPManager, AnalyticsProvider: FirebaseAnalytics.getInstance(), AuthPromoCodePlugin},

  mixin: {
    async created() {
      
    },

    async beforeUnmount() {
      
    },

    methods: {

      // Init after a2u initialization
      async initModulesOnAppInit(a2u) {
        // Log the code version
        console.log(`Init modules on app init`, a2u?.getCodeVersion());

        
      },
    }
  },

  customDbModels: {
    'users': Users
  },
}
